<template>
  <section class="new-case-view case-creation" id="single-case-view">
    <div class="container-default">
      <div class="primary-content-view-new" v-loading="loading">
        <div
          class="listings listings__table listings__all-cases"
          id="all-cases-listings"
        >
          <el-table
            :data="bulkAccessionTableData.data"
            :default-sort="{
              prop: orderBy,
              order: orderType == 'asc' ? 'ascending' : 'descending',
            }"
            @sort-change="onChangeSort"
          >
            <el-table-column
              label="ACCESSION ID"
              width="200"
              fixed="left"
              prop="accession_id"
              sortable="cust0om"
            >
              <template v-slot="scope">
                <div
                  class="name-accession cursor"
                  @click="goToCaseView(scope.row._id)"
                >
                  <span id="accession-id-blue">
                    {{ scope.row.accession_id ? scope.row.accession_id : "--" }}
                  </span>
                </div></template
              >
            </el-table-column>
            <el-table-column
              label="PATIENT NAME"
              width="250"
              sortable="custom"
              prop="patient_info.first_name"
            >
              <template v-slot="scope">
                <div class="name-accession">
                  <span
                    id="patient-name"
                    v-if="
                      scope.row.patient_info &&
                      scope.row.patient_info.middle_name
                    "
                  >
                    {{
                      scope.row.patient_info
                        ? scope.row.patient_info.first_name +
                          " " +
                          scope.row.patient_info.middle_name +
                          " " +
                          scope.row.patient_info.last_name
                        : "--"
                    }}
                  </span>
                  <span id="patient-name" v-else>
                    {{
                      scope.row.patient_info
                        ? scope.row.patient_info.first_name +
                          " " +
                          scope.row.patient_info.last_name
                        : "--"
                    }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="PATIENT DOB" width="130">
              <template v-slot="scope">
                <span v-if="scope.row.patient_info">
                  {{
                    scope.row.patient_info.date_of_birth
                      ? formatDate(scope.row.patient_info.date_of_birth)
                      : ""
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              label="CASE TYPE"
              width="210"
              sortable="custom"
              prop="case_types"
            >
              <template v-slot="scope">
                <div
                  class="case-type"
                  v-if="scope.row.case_types && scope.row.case_types.length > 1"
                >
                  <el-dropdown>
                    <span class="case-data-type">
                      {{ getCaseTypes(scope.row.case_types)[0] }}
                      <img
                        src="@/assets/images/case-type-arrow.svg"
                        alt="icon"
                      />
                    </span>

                    <template #dropdown>
                      <el-dropdown-menu class="case-type-dropdown">
                        <el-dropdown-item
                          class="cases-view"
                          v-for="(caseType, index) of getCaseTypes(
                            scope.row.case_types
                          )"
                          :key="index"
                        >
                          {{ caseType }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <div
                  v-else-if="
                    scope.row.case_types && scope.row.case_types.length == 1
                  "
                  class="case-type"
                >
                  <span class="case-data-type">
                    {{ getCaseTypes(scope.row.case_types)[0] }}
                  </span>
                </div>
                <div v-else></div>
              </template>
            </el-table-column>
            <el-table-column label="ORDERING FACILITIES" width="250">
              <template v-slot="scope">
                <div v-if="scope.row.hospital">
                  {{ scope.row.hospital ? scope.row.hospital.name : "--" }}
                </div></template
              >
            </el-table-column>
            <el-table-column label="PHYSICIAN" width="210">
              <template v-slot="scope">
                <div v-if="scope.row.ordering_physician">
                  {{
                    scope.row.ordering_physician
                      ? scope.row.ordering_physician.name
                      : "--"
                  }}
                </div></template
              >
            </el-table-column>
            <el-table-column
              label="STATUS"
              width="200"
              sortable="custom"
              prop="status"
            >
              <template v-slot="scope">
                <div class="column-status">
                  <el-tag :type="getStatusType(scope.row.status)">{{
                    scope.row.status
                  }}</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="action"
              label="Actions"
              width="100"
              fixed="right"
            >
              <template v-slot="scope">
                <ul class="listings__action-buttons">
                  <li
                    @click="goToApprovePopup(scope.row.message)"
                    v-if="scope.row.status == 'FAILED'"
                  >
                    <el-button class="view-button">
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/view-icon.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/view-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="listings listings__pagination">
          <el-pagination
            v-model:currentPage="page"
            v-model:page-size="pageSize"
            :page-sizes="[10, 25, 50, 100]"
            :small="small"
            :disabled="disabled"
            :background="background"
            layout=" prev, pager, next, jumper, sizes ,total"
            :total="bulkAccessionTableData.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <div class="default-modal">
      <div v-if="errorPopup">
        <el-dialog top="10%" width="35%" v-model="errorPopup">
          <div class="dialog-header">
            <span class="header-part"> FAILED </span>
            <el-button
              size="medium"
              class="el-white"
              type="info"
              plain
              @click="errorPopup = false"
            >
              <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
            /></el-button>
          </div>

          <div class="popup-date" style="color: red">
            {{ this.hasError }}
          </div>
        </el-dialog>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import caseConstants from "@/config/constants/caseConstants";
export default {
  components: {},
  data() {
    return {
      errorPopup: false,
      hasError: "",
      page: 1,
      pageSize: 25,
      loading: false,
      orderBy: "",
      orderType: "",
      bulkAccessionTableData: {},
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
      bulkAccession: null,
    };
  },
  props: {
    selectedStatuses: {
      default: [],
    },
  },
  computed: {
    getCaseTypes() {
      return (caseTypes) => {
        return (caseTypes || []).map((e) => this.caseTypeMap[e]);
      };
    },
    getStatusType() {
      return (statusVal) => {
        const status = statusVal.trim();
        if (status == "ACTIVE") {
          return "default";
        } else if (status == "COMPLETE") {
          return "success";
        } else if (status == "FAILED") {
          return "danger";
        } else {
          return "";
        }
      };
    },
    ...mapGetters("bulkAccessions", [
      "getSingleBulkAccession",
      "getBulkAccession",
    ]),
  },
  mounted() {
    this.fetchBulkAccession();
  },
  methods: {
    goToApprovePopup(data) {
      this.errorPopup = true;
      this.hasError = data;
    },
    goToCaseView(caseId) {
      this.$router.push({
        name: "CaseView",
        params: { case_id: caseId },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    onChangeSort({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "asc" : "desc";
      } else {
        this.orderBy = "";
        this.orderType = "";
      }
      this.fetchBulkAccession();
    },

    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchBulkAccession();
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchBulkAccession();
    },
    prepareParams() {
      const params = {
        page: this.page,
        limit: this.pageSize,
        bulk_accession_id: this.$route.params.bulk_accession_id,
      };
      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      params.status = this.selectedStatuses;
      return params;
    },
    async fetchBulkAccession() {
      try {
        this.loading = true;
        let params = this.prepareParams();
        await this.$store.dispatch("bulkAccessions/fetchBulkAccession", params);
        this.bulkAccessionTableData = this.getSingleBulkAccession;
        this.bulkAccession = this.getBulkAccession;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.case-view-body {
  .view-cases {
    &.search-btn {
      ul {
        padding-bottom: 10px;
        li {
          .search-with-filter-input {
            position: relative;
            .el-select {
              .el-input__inner {
                position: relative;
                display: inline-flex;
                max-width: 158px;
                height: 35px;
                padding-left: 30px;
                color: #000;
                background: #fff;
                box-shadow: none;
                border: 1px solid #e0e0e0;
                border-radius: 6px;
                cursor: pointer;
              }
              .el-input__suffix {
                right: 0;
                height: 97%;
                background: #0a5da6;
                border-radius: 0 5px 5px 0;
              }
            }
            .search-icon {
              position: absolute;
              top: 17px;
              bottom: 199px;
              left: 7px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20px;
            }
          }
          .search-accession {
            position: relative;
            .el-input {
              .el-input__inner {
                height: 35px;
                min-height: 35px;
                padding-right: 20px;
                border-radius: 5px;
              }
            }
            .search-icon {
              position: absolute;
              top: 7px;
              right: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20px;
            }
          }
        }
      }
    }
  }
  .case-creation__card {
    .edit-view {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .view-cases {
      .el-input__inner {
        height: 30px;
      }
      .value {
        color: #0a2342;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .case-creation__heading {
      .btn {
        min-height: 30px;
        padding: 0;
        margin: 0;
        border: none;
        .edit-icon {
          display: flex;
          align-items: center;
          margin-right: 10px;
          p {
            color: #828282;
          }
        }
        img {
          width: 15px;
          height: 15px;
          margin-right: 6px;
        }
        &:focus {
          background-color: #fff;
        }
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
  .case-creation__footer {
    .look-up-button {
      min-height: 20px;
      padding: 8px 19px 8px 19px;
      color: #fff;
      font-weight: 600;
      background: #273480;
      border-radius: 5px;
    }
  }
  @media screen and (min-width: 1600px) {
    .container-sm {
      max-width: 95%;
      margin: 0 auto;
    }
  }
}
</style>
